import { QueryClientProvider} from "react-query";
import {useQueryContext} from "./core/context/query";
import Index from './pages'
import './core/i18n'
import {getMainTheme} from "./core/utils/theme";
import {ThemeProvider} from "styled-components";
import {useMainContext} from "./core/context/main";
import StoreProvider from "./core/context/store";


const App = () => {

    const { queryClient } = useQueryContext()
    const { theme, lang } = useMainContext()

    return (
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <ThemeProvider theme={getMainTheme(theme, lang)}>
              <Index />
          </ThemeProvider>
        </StoreProvider>
      </QueryClientProvider>
    );
}

export default App;
