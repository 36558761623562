import axios from 'axios';
import { BASE_URL, STORAGE_URL } from "../constants/urls";
import cookie from "../services/cookie";

const axiosClient = axios.create();

let queue = []
let refreshing = false

const processQueue = (token) => {
    queue.forEach(prom => {
        prom.resolve(token);
    })
    console.log('proccessing queue', queue, refreshing)
    queue = [];
}

axiosClient.interceptors.request.use(
    config => {
        const token = cookie.get('token');
        if (token) {
            if (config.url.includes(STORAGE_URL)) {
                delete config.headers['x-api-key']
            } else {
                config.headers['x-api-key'] = token;
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    res => {
        return res;
    },
    async err => {
        const originalConfig = err.config;

        if (originalConfig.url !== '/login' && err.response) {

            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {

                if (refreshing) {
                    return new Promise(function (resolve, reject) {
                        queue.push({ resolve, reject })
                    }).then(token => {
                        originalConfig.headers['x-api-key'] = token
                        return axiosClient.request(originalConfig);
                    }).catch(err => {
                        return Promise.reject(err);
                    })
                }

                refreshing = true

                originalConfig._retry = true;
                try {
                    const rs = await axios.post(
                        BASE_URL + 'auth/refreshToken',
                        {
                            refreshToken: cookie.get('refresh-token')
                        }
                    );

                    const access = rs.data.data.accessToken;
                    const refresh = rs.data.data.refreshToken;

                    cookie.set('token', access, { path: '/' });
                    cookie.set('refresh-token', refresh, { path: '/' });

                    refreshing = false
                    processQueue(rs.data.data.accessToken)

                    return axiosClient(originalConfig);
                } catch (_error) {

                    cookie.delete('token');
                    cookie.delete('refresh-token');
                    window.location.href = window.location.origin + '/login';
                    return Promise.reject(_error);
                }

            }
        }

        return Promise.reject(err);
    }
);


export default axiosClient;

