// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-btn {
    cursor: pointer;
    border-radius: 0.375rem;
    padding: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem
}
.mini-btn:hover {
    background-color: #ffffff14
}
:is(.dark .mini-btn:hover) {
    background-color: #ffffff14
}
@media (min-width: 1050px) {
    .mini-btn {
        font-size: 1rem;
        line-height: 1.5rem
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/btn.css"],"names":[],"mappings":"AACI;IAAA,eAAgG;IAAhG,uBAAgG;IAAhG,gBAAgG;IAAhG,mBAAgG;IAAhG;AAAgG;AAAhG;IAAA;AAAgG;AAAhG;IAAA;AAAgG;AAAhG;IAAA;QAAA,eAAgG;QAAhG;IAAgG;AAAA","sourcesContent":[".mini-btn {\n    @apply dark:hover:bg-d-hover hover:bg-l-hover p-1 rounded-md cursor-pointer lp:text-base text-sm;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
