import {useQuery} from "react-query";
import {normalFetch} from "../../fetch-api/get";


export const SettingKeys = {
    getCurrencies: 'get-currencies',
    getParams: 'get-params'
}

export const useCurrencies = () => {

    return useQuery(
        SettingKeys.getCurrencies, () => normalFetch('settings/currency'),
        {
            select: res => res?.data?.data,
            cacheTime: 30 * 60 * 1000,
            staleTime: 30 * 60 * 1000
        }
    )
}

export const useGetParams = () => {

    return useQuery(
        SettingKeys.getParams, () => normalFetch('settings/params'),
        {
            select: res => res?.data?.data,
            cacheTime: 30 * 60 * 1000,
            staleTime: 30 * 60 * 1000
        }
    )
}
