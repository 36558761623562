import {useContext, createContext} from "react";
import { useProfile } from "../services/react-query/auth/auth";
import {useCurrencies, useGetParams} from "../services/react-query/setting";



const context = createContext({})

export const useStore = () => {
    return useContext(context)
}

const StoreProvider = ({ children }) => {

    const {data: profile, refetch: refetchProfile} = useProfile()
    const { data: currencies } = useCurrencies()
    const { data: params } = useGetParams()

    return (
        <context.Provider value={{
            profile, refetchProfile,
            currencies, params
        }}>
            {children}
        </context.Provider>
    )
}

export default StoreProvider;
